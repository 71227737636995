import L from "leaflet";

export const PointMarker = (point, onClick) => {
  const icon = L.divIcon({ className: "active-marker" });

  const marker = L.marker([point.coordinates.latitude, point.coordinates.longitude], {
    applianceId: point.appliance.id,
    pointId: point.id,
    icon,
  });

  marker.bindTooltip(point.appliance.call_sign, {
    interactive: true,
    permanent: true,
  }).openTooltip();

  if (typeof onClick === "function") {
    marker.on("click", onClick);
  }

  return marker;
};
