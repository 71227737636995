<template>
  <ul>
    <li
      v-for="type in types"
      :key="type.id"
      :class="{ 'is-active': selectedStaticTypeIds.includes(type.id)}"
      @click="setStaticType(type)"
    >
      {{ type.name }}
    </li>
  </ul>
</template>

<script>
import { mapActions, mapState } from "vuex";

import { fetchTypes } from "../../api/static";

export default {
  data () {
    return {
      /** @type {StaticType[]} */
      types: [],
    };
  },

  computed: {
    ...mapState({
      selectedStaticTypeIds: state => state.selectedStaticTypeIds,
    }),
  },

  methods: {
    ...mapActions({
      toggleStaticTypeId: "toggleStaticTypeId",
    }),

    /** @param type {StaticType} */
    async setStaticType (type) {
      this.toggleStaticTypeId(type.id);
    },
  },

  async mounted () {
    const types = await fetchTypes();
    this.types = types;
  },
};
</script>

<style lang="css" scoped>
li {
  list-style: none;
  display: block;
  background: rgba(210, 210, 210, 0.35);
  padding: 10px;
  margin-bottom: 1px;
  cursor: pointer;
}

li.is-active {
  background: rgba(2, 116, 217, 0.35);
}
</style>
