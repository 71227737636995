import L from "leaflet";

/** @param item {StaticObject} */
export default function (map, item) {
  const icon = L.divIcon(
    {
      className: "falcon-marker is-rescuer is-normal",
      html: `
        <div class="static-icon">
          <img src="${item.type.icon}">
          <div class="static-icon--text">${item.name}</div>
        </div>`,
      iconSize: "auto",
    });
  const { latitude, longitude } = item.coordinates;

  const marker = L.marker([latitude, longitude], { icon });
  return marker.addTo(map);
}
