import { defineComponent, onBeforeMount, ref } from "@vue/composition-api";
import AppIconLink from "@/components/global/icons/Link.vue";
import { formatDate } from "@/utils/date";
import { getEventsByFilters } from "@/services/event/get-events-by-filters";
import useVuex from "@/composable/use-vuex";
export default defineComponent({
    name: "EventList",
    inheritAttrs: false,
    components: {
        AppIconLink,
    },
    setup() {
        const store = useVuex();
        const activeEvents = ref([]);
        const selectedActiveEventId = ref(0);
        const createdEvents = ref([]);
        const selectedCreatedEventId = ref(0);
        const setSelectedEventId = (type, event) => {
            if (type === "active") {
                selectedActiveEventId.value = event.id;
            }
            if (type === "created") {
                selectedCreatedEventId.value = event.id;
            }
            store.value.dispatch("map_center", [event.coordinate.latitude, event.coordinate.longitude]);
        };
        onBeforeMount(async () => {
            // TODO: add pagination
            const crtEvents = await getEventsByFilters.run({ status: "created", limit: 1000 });
            createdEvents.value = crtEvents.data;
            // TODO: add pagination
            const actEvents = await getEventsByFilters.run({ status: "active", limit: 1000 });
            activeEvents.value = actEvents.data;
        });
        return {
            eventsDownloading: getEventsByFilters.active,
            activeEvents,
            selectedActiveEventId,
            createdEvents,
            selectedCreatedEventId,
            setSelectedEventId,
            formatDate,
        };
    },
});
