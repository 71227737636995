<template>
  <div>
    <div v-module-class="'block'">
      <p v-module-class="['title', {selected: method === 'range'}]">
        За проміжок часу
      </p>

      <p v-module-class="'item'">
        <input
          type="datetime-local"
          :value="startDateStr"
          @change="setDate('start', $event.target.value)"
        >
      </p>
      <p v-module-class="'item'">
        <input
          type="datetime-local"
          :value="endDateStr"
          @change="setDate('end', $event.target.value)"
        >
      </p>
    </div>

    <div v-module-class="'block'">
      <p v-module-class="['title', {selected: method === 'interval'}]">
        За період часу
      </p>

      <p
        v-for="time in timeIntervals"
        :key="time.minutes"
        v-module-class="'item'"
      >
        <button
          v-module-class="{selected: time.minutes===timeInterval}"
          @click="setTimeInterval(time.minutes)"
        >
          {{ time.title }}
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import now from "dayjs";

export default {
  name: "DateRange",
  data: () => ({
    /** @type {"interval" | "range"} */
    // @ts-ignore
    method: "interval",

    /** @type {Array<{minutes: number, title: string}>} */
    timeIntervals: [
      { minutes: 1, title: "1 хвилина" },
      { minutes: 5, title: "5 хвилин" },
      { minutes: 10, title: "10 хвилин" },
      { minutes: 15, title: "15 хвилин" },
      { minutes: 30, title: "30 хвилин" },
      { minutes: 60, title: "1 година" },
      { minutes: 120, title: "2 години" },
      { minutes: 360, title: "6 годин" },
      { minutes: 720, title: "12 годин" },
      { minutes: 1440, title: "24 години" },
      { minutes: 2880, title: "2 доби" },
    ],

    timeInterval: 120,
    startDate: now().subtract(2, "day"),
    endDate: now(),
  }),

  computed: {
    startDateStr () {
      return this.startDate.format("YYYY-MM-DDTHH:mm");
    },
    endDateStr () {
      return this.endDate.format("YYYY-MM-DDTHH:mm");
    },
  },

  methods: {
    /** @param method {"interval" | "range"} */
    setMethod (method) {
      this.method = method;
    },

    /** @param val {number} */
    setTimeInterval (val) {
      this.timeInterval = val;
      this.setMethod("interval");
      this.setDateRange();
    },

    /**
     * @param type {"start" | "end"}
     * @param value {string}
     */
    setDate (type, value) {
      if (type === "start") this.startDate = now(value);
      if (type === "end") this.endDate = now(value);
      this.setMethod("range");
      this.setDateRange();
    },

    setDateRange () {
      const dateRange = this.method === "interval"
        ? [now().subtract(this.timeInterval, "minute"), now()]
        : [this.startDate, this.endDate];

      const dateRangeStr = dateRange.map(d => d.toISOString()).join(",");
      this.$store.dispatch("daterange", dateRangeStr);
    },
  },

  mounted () {
    this.setDateRange();
  },
};
</script>

<style lang="scss" module>
.block {
  text-align: center;
  margin: 10px 0;

  &:first-of-type { margin-top: 0; }
  &:last-of-type { margin-bottom: 0; }
}

.title {
  font-size: 16px;
  background: rgba(210, 210, 210, 0.35);
  padding: 10px;

  &.selected {
    background: rgba(2, 116, 217, 0.35);
  }
}

.item {
  margin: 5px;

  > input {
    height: 40px;
    padding: 15px;
  }

  > button {
    display: block;
    border: 0;
    background: rgba(210, 210, 210, 0.35);
    padding: 10px;
    width: 100%;
    cursor: pointer;

    &.selected {
      background: rgba(2, 116, 217, 0.35);
    }
  }
}
</style>
