import { request } from "./request";
import generateParams from "../utils/generate-params";
// DEBUG: test data
// import dataP from "./fixtures/points.json";
// TODO: change dateRange to [Date, Date]
export function fetchTracks(appliancesIds, dateRange) {
    if (appliancesIds.length === 0)
        return Promise.resolve([]);
    const params = { ordering: "-time", limit: 1000 };
    Object.assign(params, generateParams("time", "range", dateRange));
    Object.assign(params, generateParams("appliance", "in", appliancesIds, ","));
    // DEBUG: test data
    // return dataP;
    return request.get("/points", { params }).then(res => res.results);
}
export function fetchLastPoints(appliancesIds, dateRange) {
    const params = { limit: 1000 };
    Object.assign(params, generateParams("time", "range", dateRange));
    Object.assign(params, generateParams("appliance", "in", appliancesIds, ","));
    return request
        .get("/appliances", { params })
        .then(res => res.results
        .filter(appliance => appliance.last_point !== null)
        .map(appliance => appliance.last_point));
}
export function fetchLastPointOfAppliance(applianceId) {
    return request
        .get(`/appliances/${applianceId}`)
        .then(res => res.last_point);
}
