<template lang="pug">
.altitude(v-show="points.length")
  .heightScale
    div(v-for="index in 10" :key="index") #[span {{max / index | toFixed2}}]
  .pillar(v-for="p in points" :style="{height: p.altitude / max * 100 + '%'}" @mouseover="hover(p)")
</template>

<script>
import { mapState } from "vuex";
export default {

  computed: {
    ...mapState({
      _appliancesPoints: state => state.department.appliancesPoints,
      _centeredApplianceId: state => state.department.centeredApplianceId,
    }),

    points () {
      return this._appliancesPoints.filter(p => (
        p.appliance.id === this._centeredApplianceId &&
        p.altitude !== null
      ));
    },

    max () {
      return Math.max(...this.points.map(p => p.altitude));
    },
  },

  methods: {
    hover (p) {
      // TODO
      // this.$store.dispatch("hoverAltitude", p);
    },
  },
};
</script>

<style lang="css" scoped>
.altitude {
  position: absolute;
  bottom: 10px;
  left: 400px;
  right: 400px;
  height: 30vh;
  padding-left: 40px;
  background: #FFFFFFF1;
  z-index: 4080;
  display: flex;
  align-items: flex-end;
}

.pillar {
  width: 5px;
  background: blue;
  border: 1px solid #333333;
}

.heightScale {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.heightScale div {
  height: 10%;
  font-size: 12px;
}

.heightScale div span {
  background: #FFFFFF;
}
</style>
