<template>
  <div>
    <i v-if="title && list.length>0" :class="titleClass">{{ title }}</i>

    <TrackingUnit
      v-for="unit in list"
      :key="unit.title"
      :unit="unit"
      :is-active="selectedApplianceIds.includes(unit.appliance && unit.appliance.id)"
      :is-centered="unit.appliance !== null ? centeredApplianceId === unit.appliance.id : false"
      v-bind="$attrs"
      @delete-unit="emitDelete"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

import TrackingUnit from "./TrackingUnit.vue";

export default {
  name: "TrackingList",
  inheritAttrs: false,

  components: {
    TrackingUnit,
  },
  props: {
    list: {
      /** @type {import("vue").PropType<Array<TrackingUnit>>} */
      type: Array,
      required: true,
    },
    title: { type: String, default: "" },
    titleClass: { type: String, default: "" },
  },
  computed: {
    ...mapState({
      centeredApplianceId: state => state.department.centeredApplianceId,
      selectedApplianceIds: state => state.department.selectedApplianceIds,
    }),
  },
  methods: {
    /** @param unit {TrackingUnit} */
    emitDelete (unit) {
      // eslint-disable-next-line vue/require-explicit-emits
      this.$emit("delete-unit", unit);
    },
  },
};
</script>
