import { defineComponent } from "@vue/composition-api";
import systemLogout from "@/store/logout";
export default defineComponent({
    name: "UserSettings",
    inheritAttrs: false,
    setup() {
        const logout = async () => {
            await systemLogout();
        };
        return {
            logout
        };
    }
});
