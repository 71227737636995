<template>
  <div>
    <AppAutocomplete
      v-slot="{result}"
      :search="search"
      @submit="handleSubmit"
    >
      <span>{{ getResultValue(result).name }}</span>
      &nbsp;
      <strong v-if="getResultValue(result).appliance">{{ getResultValue(result).appliance.call_sign }}</strong>
      <span v-else>(без приладу)</span>
    </AppAutocomplete>

    <TrackingList
      :list="list"
      deletable
      center-immediately
      @delete-unit="deleteUnit"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import AppAutocomplete from "../global/Autocomplete.vue";
import TrackingList from "./TrackingList.vue";

import { fetchRescuersByQuery } from "../../api/office";
import { fetchVehiclesByQuery } from "../../api/vehicle";
import { Vehicle } from "../../models/Vehicle";

export default {
  components: {
    TrackingList,
    AppAutocomplete,
  },
  data: () => ({
    /** @type {TrackingUnit[]} */
    list: [],
  }),
  methods: {
    ...mapActions({
      _setVisibleApplianceIds: "department/setVisibleApplianceIds",
    }),

    /** @param input {string} */
    async search (input) {
      if (input.length < 2) return [];

      const [rescuers, vehicles] = await Promise.all([
        fetchRescuersByQuery(input),
        fetchVehiclesByQuery(input),
      ]);
      return [...rescuers, ...vehicles];
    },

    /** @param result {ApiUser | Vehicle} */
    getResultValue (result) {
      const name = result instanceof Vehicle
        ? result.brand
        : `${result.last_name ?? ""} ${result.first_name ?? ""} ${result.middle_name ?? ""}`;

      // @ts-ignore -> position always exist because user is Rescuer
      const appliance = result instanceof Vehicle ? result.ownership.appliance : result.position.appliance;

      // @ts-ignore -> position always exist because user is Rescuer
      const departmentId = result instanceof Vehicle ? result.ownership.department.id : result.position.location.id;

      return { name, appliance, departmentId };
    },

    /** @param result {undefined | ApiUser | Vehicle} */
    handleSubmit (result) {
      if (result === undefined) return;

      const { name, appliance, departmentId } = this.getResultValue(result);
      const exist = this.list.find(u => u.title === name) !== undefined;

      if (!exist) {
        this.list.push({
          title: name,
          appliance,
          departmentId,
        });
      }
    },

    /** @param unit {TrackingUnit} */
    deleteUnit (unit) {
      this.list = this.list.filter(u => {
        return u.title !== unit.title;
      });
    },
  },
  watch: {
    list (value) {
      // @ts-ignore
      const applianceIds = this.list.filter(u => u.appliance !== null).map(u => u.appliance.id);
      this._setVisibleApplianceIds(applianceIds);
    },
  },
};
</script>
