module.exports.fields = {
  name: "ім'я",
  lastname: "прізвище",
  surname: "по батькові",
  phone: "телефон",
  email: "email",
};
const deltaDeg = 11.25;
module.exports.direction = function (deg) {
  let n = 348.75;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "n";
  }
  n = 11.25;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "nne";
  }
  n = 33.75;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "ne";
  }
  n = 56.25;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "ene";
  }
  n = 78.75;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "e";
  }
  n = 101.25;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "ese";
  }
  n = 123.75;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "se";
  }
  n = 146.25;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "sse";
  }
  n = 168.75;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "s";
  }
  n = 191.25;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "ssw";
  }
  n = 213.75;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "sw";
  }
  n = 236.25;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "wsw";
  }
  n = 258.75;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "w";
  }
  n = 281.25;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "wnw";
  }
  n = 303.75;
  if (deg >= n - deltaDeg && deg < n + deltaDeg) {
    return "nw";
  }
  return "nnw";
};

module.exports.sidebarPanes = [
  {
    id: "events-wrap",
    tab: "<i class=\"fa fa-info-circle\"></i>",
    pane: "<div id=\"event-app\">event-app</div>",
    title: "Події",
    position: "top",
  },
  {
    id: "static",
    tab: "<i class=\"fas fa-monument\"></i>",
    pane: "<div id=\"static-app\">static-app</div>",
    title: "Статичні об`єкти",
    position: "top",
  },
  {
    id: "mrsus",
    tab: "<i class=\"fas fa-users\"></i>",
    pane: "<div id=\"mrsus-app\">mrsus-app</div>",
    title: "Трекери",
    position: "top",
  },
  {
    id: "search",
    tab: "<i class=\"fas fa-search\"></i>",
    pane: "<div id=\"search-app\">search-app</div>",
    title: "Пошук користувачів",
    position: "top",
  },
  {
    id: "maptype",
    tab: "<i class=\"fas fa-layer-group\"></i>",
    pane: "<div id=\"maptype-app\">maptype-app</div>",
    title: "Тип мапи",
    position: "top",
    // is_mobile: true
  },
  {
    id: "daterange",
    tab: "<i class=\"fas fa-calendar-alt\"></i>",
    pane: "<div id=\"daterange-app\">daterange-app</div>",
    title: "Відслідкування від - до",
    position: "top",
    is_mobile: false,
  },
  {
    id: "search-location",
    tab: "<i class=\"fas fa-search-location\"></i>",
    pane: "<input id=\"locationSearchSide\" class=\"input\">",
    title: "Пошук місця",
    position: "top",
    is_mobile: true,
  },
  {
    id: "weather-link",
    tab: "<i class=\"fas fa-cloud-sun\"></i>",
    pane: "<div id=\"weather-app\">weather-app</div>",
    title: "Погода по точці",
    position: "top",
    is_mobile: true,
  },
  
  {
    id: "user",
    tab: "<i class='fas fa-user'></i>",
    pane: "<div id=\"user-settings\">user-settings</div>",
    title: "Налаштування користувача",
    position: "bottom",
  },
  {
    id: "admin",
    tab: "<i class='fas fa-user-cog' title='Перейти до адміністративної сторінки'></i>",
    button: '/admin',
    title: "Адміністративна сторінка",
    position: "bottom",
  },
  {
    id: "options",
    tab: "<i class=\"fas fa-bars\"></i>",
    pane: "<div id=\"options-app\">options-app</div>",
    title: "Звіти",
    position: "bottom",
  },
];
