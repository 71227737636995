export const getText = (_number, single, several, plural) => {
    const ns = _number.toString();
    const number = Number(ns[ns.length - 1]);
    if (_number >= 10 && _number <= 19)
        return plural;
    if (number === 1)
        return single;
    if (number >= 2 && number <= 4)
        return several;
    return plural;
};
