<template>
  <div v-module-class="'mrsu-list'">
    <button
      v-module-class="'mrsu-list--clear-all-tracks'"
      @click="clearTracks"
    >
      Сховати всі треки
    </button>

    <div
      v-for="location in locations"
      :key="location.id"
      v-module-class="['item', 'location']"
      @click.stop="toggleLocation(location.id)"
    >
      <span v-module-class="{active: locationIds.includes(location.id)}">
        {{ location.title }}
      </span>

      <div
        v-for="group in groups.filter(g => g.mru.id === location.id && locationIds.includes(g.mru.id))"
        :key="group.id"
        v-module-class="['item', 'group']"
        @click.stop="toggleGroup(group.id)"
      >
        <span v-module-class="{active: groupIds.includes(group.id)}">
          - {{ group.title }}
        </span>

        <div
          v-for="department in departments.filter(d=> (d.group.id === group.id && groupIds.includes(d.group.id)))"
          :key="department.id"
          v-module-class="['item', 'department']"
          @click.stop="toggleDepartment(department.id)"
        >
          <span v-module-class="{active: departmentIds.includes(department.id)}">
            --- {{ department.title }}
            <span v-if="fetchingTrackingUnitsOfDepartmentId === department.id" v-module-class="'spinner'">
              <i class="fas fa-spinner fa-pulse" />
            </span>
          </span>

          <div @click.stop>
            <TrackingList
              v-if="departmentIds.includes(department.id)"
              :list="trackingRescuers.filter(u => u.departmentId == department.id)"
              title="Персонал:"
              :title-class="$style['unit-type']"
            />

            <TrackingList
              v-if="departmentIds.includes(department.id)"
              :list="trackingVehicles.filter(u => u.departmentId == department.id)"
              title="Транспорт:"
              :title-class="$style['unit-type']"
            />
          </div>
        </div>
      </div>
    </div>

    <div>
      <p
        @click="toggleTourists"
        v-module-class="['item', 'location']"
      >
        <span v-module-class="{active: showTourists}">
          Туристи
          <span v-if="downloadingTourists" v-module-class="'spinner'">
            <i class="fas fa-spinner fa-pulse" />
          </span>
        </span>
      </p>

      <TrackingList
        v-show="showTourists"
        :list="trackingTourists"
      />
    </div>
  </div>
</template>

<script>
import TrackingList from "./TrackingList.vue";
import { mapState, mapActions } from "vuex";

import * as api from "../../api/office";
import { fetchVehicles } from "../../api/vehicle";
import { fetchTouristUsedAppliances } from "../../api/tourist";

export default {
  name: "MRUList",
  inheritAttrs: false,

  components: {
    TrackingList,
  },

  data: () => ({
    /** @type {number[]} */
    locationIds: [],
    /** @type {number[]} */
    groupIds: [],
    /** @type {number[]} */
    departmentIds: [],

    /** @type {Location[]} */
    locations: [],
    /** @type {ApiGroup[]} */
    groups: [],
    /** @type {ApiDepartment[]} */
    departments: [],

    /** @type {ApiDepartment["id"]} */
    fetchingTrackingUnitsOfDepartmentId: -1,
    /** @type {TrackingUnit[]} */
    trackingRescuers: [],
    /** @type {TrackingUnit[]} */
    trackingVehicles: [],
    
    downloadingTourists: false,
    showTourists: false,
    /** @type {TrackingUnit[]} */
    trackingTourists: [],
  }),

  computed: {
    ...mapState({
      _centeredApplianceId: state => state.department.centeredApplianceId,
    }),
  },

  methods: {
    ...mapActions({
      _setVisibleApplianceIds: "department/setVisibleApplianceIds",
      _setCenteredApplianceId: "department/setCenteredApplianceId",
      _clearAllSelectedApplianceIds: "department/clearAllSelectedApplianceIds",
      _deselectApplianceIds: "department/deselectApplianceIds",
    }),

    async fetchTrackingUnits () {
      const positions = await api.fetchPositions(this.departmentIds);
      const vehicles = await fetchVehicles(this.departmentIds);

      this.trackingRescuers = positions.map(position => ({
        title: `${position.user.last_name ?? ""} ${position.user.first_name ?? ""} ${position.user.middle_name ?? ""}`,
        appliance: position.appliance,
        departmentId: position.location.id,
      }));

      this.trackingVehicles = vehicles.map(vehicle => ({
        title: vehicle.brand,
        appliance: vehicle.ownership.appliance,
        departmentId: vehicle.ownership.department.id,
      }));

      const allRescuersApplianceIds = positions.map(position => position.appliance?.id).filter(id => !!id);
      const allVehiclesApplianceIds = vehicles.map(v => v.ownership.appliance.id);
      this._setVisibleApplianceIds([...allRescuersApplianceIds, ...allVehiclesApplianceIds]);
    },

    /** @param departmentId {ApiDepartment["id"]} */
    clearTrackingUnits (departmentId) {
      const rescuerApplianceIdsToRemove = this.trackingRescuers
        .filter(u => u.departmentId === departmentId)
        .map(u => u.appliance?.id)
        .filter(id => !!id);
      this.trackingRescuers = this.trackingRescuers.filter(u => u.departmentId !== departmentId);

      const vehicleApplianceIdsToRemove = this.trackingVehicles
        .filter(u => u.departmentId === departmentId)
        .map(u => u.appliance?.id)
        .filter(id => !!id);
      this.trackingVehicles = this.trackingVehicles.filter(u => u.departmentId !== departmentId);

      this._deselectApplianceIds([...rescuerApplianceIdsToRemove, ...vehicleApplianceIdsToRemove]);
    },
    /** @param groupId {ApiGroup["id"]} */
    clearDepartments (groupId) {
      const departmentsIdsToDelete = this.departments.filter(d => d.group.id === groupId).map(d => d.id);
      this.departmentIds = this.departmentIds.filter(dId => !departmentsIdsToDelete.includes(dId));
      departmentsIdsToDelete.forEach(this.clearTrackingUnits);
    },
    /** @param locationId {Location["id"]} */
    clearGroups (locationId) {
      const groupIdsToDelete = this.groups.filter(g => g.mru.id === locationId).map(g => g.id);
      this.groupIds = this.groupIds.filter(gId => !groupIdsToDelete.includes(gId));
      groupIdsToDelete.forEach(this.clearDepartments);
    },

    /** @param id {Location["id"]} */
    toggleLocation (id) {
      const pushed = this.toggleItem("location", id);
      if (!pushed) this.clearGroups(id);
    },
    /** @param id {ApiGroup["id"]} */
    toggleGroup (id) {
      const pushed = this.toggleItem("group", id);
      if (!pushed) this.clearDepartments(id);
    },
    /** @param id {ApiDepartment["id"]} */
    async toggleDepartment (id) {
      if (this.fetchingTrackingUnitsOfDepartmentId === id) return;

      const pushed = this.toggleItem("department", id);
      if (pushed) {
        this.fetchingTrackingUnitsOfDepartmentId = id;
        await this.fetchTrackingUnits();
        this.fetchingTrackingUnitsOfDepartmentId = -1;
      }
      else {
        this.clearTrackingUnits(id);
      }
    },

    /**
     * Toggle one of Location | ApiGroup| Department id
     * @param itemName {"department" | "group" | "location"}
     * @param id {number}
     */
    toggleItem (itemName, id) {
      // @ts-ignore
      const list = this[`${itemName}Ids`];
      const index = list.indexOf(id);
      const exist = index !== -1;

      if (exist) list.splice(index, 1);
      else list.push(id);

      return !exist;
    },

    clearTracks () {
      this._clearAllSelectedApplianceIds();
      this._setVisibleApplianceIds([]);
      this._setCenteredApplianceId(-1);
    },

    async toggleTourists() {
      if(this.showTourists === false) {
        await this.downloadTouristAppliances();
        // @ts-ignore
        this._setVisibleApplianceIds(this.trackingTourists.map(t => t.appliance.id))
      }

      this.showTourists = !this.showTourists;
    },

    async downloadTouristAppliances() {
      this.downloadingTourists = true;

      const appliances = await fetchTouristUsedAppliances()
      this.trackingTourists = appliances
        .filter(a => a.last_used.is_currently_used)
        .map(appliance => ({
          title: `${appliance.last_used.user.last_name ?? ""} ${appliance.last_used.user.first_name ?? ""} ${appliance.last_used.user.middle_name ?? ""}`,
          appliance,
          departmentId: 0,
        }));

      this.downloadingTourists = false;
    },
  },

  watch: {
    /** @param units {TrackingUnit[]} */
    trackingUnits (units) {
      const centeredUnit = units.find(u => u.appliance?.id === this._centeredApplianceId);
      if (centeredUnit === undefined) this._setCenteredApplianceId(-1);
    },
  },

  async mounted () {
    this.locations = await api.fetchLocations({ limit: 100 });
    this.groups = await api.fetchGroups([], { limit: 100 });
    this.departments = await api.fetchDepartments([],{ limit: 100 });
  },
};
</script>

<style lang="scss" module>
.mrsu-list {
  .item {
    margin: 2px 0;

    > span {
      padding: 10px;
      background: rgba(210, 210, 210, 0.35);
      display: block;
      cursor: pointer;

      &.active {
        background: rgba(2, 116, 217, 0.35);
      }
    }

    &.location {
      font-size: 16px;
    }

    &.group {
      font-size: 14px;
    }

    &.department {
      font-size: 13px;
    }
  }

  .spinner {
    margin-left: 15px;
  }

  .unit-type {
    margin: 5px 11px 2px;
  }

  .mrsu-list--clear-all-tracks {
    display: block;
    background: rgba(2, 116, 217, 0.35);
    border: 1px solid rgba(0, 0, 0, 0.7);
    margin: 5px auto;
    padding: 5px 15px;
    cursor: pointer;
    font-size: 14px;
  }
}
</style>
