<template>
  <div
    v-module-class="['tracking-unit', { 'is-active': isActive }]"
    @click.stop="toggleTracking()"
  >
    <span>{{ unit.title }}</span>

    <span v-if="unit.appliance === null" key="none">без приладу</span>
    <span v-else key="exist">
      <strong>{{ unit.appliance.call_sign }}</strong>

      <span v-module-class="'tracking-unit-icons'">
        <span :key="isCentered" @click.stop="centerAppliance()">
          <i
            class="fas fa-bullseye"
            :class="{ 'is-blue': isCentered }"
          />
        </span>

        <span v-if="deletable" @click.stop="emitDelete()">
          <i class="far fa-times-circle" />
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TrackingUnit",
  inheritAttrs: false,

  props: {
    deletable: { type: Boolean, default: false },
    isActive: { type: Boolean, required: true },
    isCentered: { type: Boolean, required: true },
    centerImmediately: { type: Boolean, default: false },
    unit: {
      /** @type {import("vue").PropType<TrackingUnit>} */
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapActions({
      _toggleApplianceId: "department/toggleApplianceId",
      _setCenteredApplianceId: "department/setCenteredApplianceId",
    }),

    centerAppliance () {
      if (this.unit.appliance === null) return;

      this._setCenteredApplianceId(this.unit.appliance.id);
      if (!this.isActive) this._toggleApplianceId(this.unit.appliance.id);
    },

    async emitDelete () {
      if (this.unit.appliance === null) return;

      await this._toggleApplianceId(this.unit.appliance.id);
      await this._setCenteredApplianceId(-1);
      // eslint-disable-next-line vue/require-explicit-emits
      this.$emit("delete-unit", this.unit);
    },

    toggleTracking () {
      if (this.unit.appliance === null) return;

      this._toggleApplianceId(this.unit.appliance.id);
    },
  },

  mounted () {
    if (this.centerImmediately) this.centerAppliance();
  },
};
</script>

<style lang="scss" module>
.tracking-unit {
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin: 1px;
  background: rgba(210, 210, 210, 0.35);
  padding: 10px;
  font-size: 12px;

  &.is-active {
    background: rgba(2, 116, 217, 0.35);
  }
}

.tracking-unit-icons {
  > * {
    margin-left: 5px;
  }
}
</style>
