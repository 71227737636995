<template lang="pug">
.control-line(v-show="window_width > 800")
  //.control-line--item
    select(v-model="source" @change="setMapType" title="Вибір типу мапи")
      option(v-for="item in map_types" :selected="map_type == item.key" :value="item.key") {{item.name}}
  .control-line--item
    input.input#locationSearch
</template>

<script>
import { mapState } from "vuex";
export default {
  data: function () {
    return {
      window_width: 1000,
      source: "osm",
    };
  },
  methods: {
    setMapType () {
      this.$store.dispatch("setMapType", this.source);
    },
  },
  mounted () {
    window.addEventListener("resize", () => {
      this.window_width = this.$windowSizes().width;
    });

    const el = document;
    const event = document.createEvent("HTMLEvents");
    event.initEvent("resize", true, false);
    el.dispatchEvent(event);

    const input = this.$el.querySelector("#locationSearch");
    const options = {
      types: [],
    };
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      this.$store.dispatch("map_center", [lat, lng]);
    });
  },
  computed: {
    ...mapState([
      "map_types",
      "map_type",
    ]),
  },
};
</script>

<style lang="sass" scoped>
</style>
