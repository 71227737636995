<template>
  <div class="map">
    <Altitude />
    <div id="mapid" />
    <div v-if="!printing && pin" class="map-point--block">
      <span @click="removePin()" class="map-point--close">
        <i class="far fa-times-circle close"></i>
      </span>
      <div><b>Довгота:</b> {{ pin.getLatLng().lng }}</div>
      <div><b>Широта:</b> {{ pin.getLatLng().lat }}</div>
    </div>
    <div v-if="!printing" class="events-alerts--wrapper">
      <div class="events-alerts--turn" @click="turnOffAlarm()">
        <AppIcon name="bell" :turn="alarmOn"/>
      </div>
      <div v-if="isDownloading">Завантаження...</div>
      <div v-if="hasEvents" class="events-alerts--block" @click="goToNewEvents()">
        <span class="events-alerts--icon"><i class="fas fa-exclamation-triangle"></i></span>
        <span>{{ events.length }} {{ textNew }} {{ textEvents }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl } from 'howler';
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-sidebar-v2";
import "leaflet-sidebar-v2/css/leaflet-sidebar.min.css";

import mouseCoordinate from "../libs/leaflet.mousecoordinate.min.js";
import edgeScaleBar from "../libs/leaflet.edgescalebar.js";
import "leaflet.gridlayer.googlemutant/Leaflet.GoogleMutant";

import Control from "../components/map/top_right_control.vue";
import MapSidebarSearch from "../components/map/MapSidebarSearch.vue";
import MapType from "../components/map/map_type.vue";
import DateRange from "../components/map/date_range.vue";
import Options from "../components/map/options.vue";
import Weather from "../components/map/weather.vue";
import PointInfo from "../components/map/PointInfo.vue";
import MRSUList from "../components/map/MRSUList.vue";
import Altitude from "../components/map/altitude.vue";
import staticLayers from "../components/map/staticLayers.vue";
import EventList from "../components/map/EventList.vue";
import UserSettings from "../components/map/UserSettings.vue";

import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";
import _ from "lodash";
import store from "../store";
import router from "../router";
import { sidebarPanes } from "../collections";

import drawPoint from "../components/helpers/drawPoint";
import drawTrack from "../components/helpers/drawTrack";
import drawStatic from "../components/helpers/drawStatic";

import { PointMarker } from "../modules/map/utils/map-points.js";
import { mapTracks } from "../modules/map/utils/map-track.js";

import * as pointApi from "../api/point";
import * as staticApi from "../api/static";

import { getWeatherByPoint } from "../services/weather/get-weather-by-point";
import { getEventsByFilters } from "../services/event/get-events-by-filters";
import { getText } from "../utils/pluralize";
import {fetchActiveEvents} from "../services/event/get-active-events/api";
import {fetchEventsByFilters} from "../services/event/get-events-by-filters/api";

const soundSrc = require("./sound.mp3");

let APPLIANCE_GROUPS_STORE_OLD = [];
let APPLIANCE_GROUPS_STORE_NEW = [];

/** @type {Array<StaticType["id"]>} */
let STATIC_TYPES_DROWN = [];

/** @type {Array<{typeId: StaticType["id"], object: any}>} */
let STATIC_OBJECTS_DROWN = [];

let POINTS_STORE = [];

let CREATED_EVENTS_POINTS = [];

export default {
  components: {
    Control,
    Altitude,
  },
  data: function () {
    return {
      map: undefined,
      alarmOn: false,
      sound: undefined,
      events: null,
      printing: false,
      pin: null,
      staticLayers: [],
      pointsLayers: [],
      tailsLayers: [],
      tilesLayers: [],
      tileSourceIndex: 0,
      tailsPointsLayers: [],
      // tracksGroup: null,
      centeredProgrammatically: false,
    };
  },

  computed: {
    ...mapState([
      "lst_point_info_id",
      "map_types",
      "map_type",
      "map_center",
      "traking_appliances",
      "points_on_map",
      "appliances_tails",
      "appliances_tails_points",
      "hovered_altitude",
      "daterange",
      "staticObjects",
    ]),
    ...mapGetters({
      _passiveAppliancesPoints: "department/passiveAppliancesPoints",
      _activeAppliancesPoints: "department/activeAppliancesPoints",
      _visibleAppliancesPoints: "department/visibleAppliancesPoints",
    }),
    ...mapState({
      _visibleApplianceIds: state => state.department.visibleApplianceIds,
      _centeredApplianceId: state => state.department.centeredApplianceId,
      _selectedApplianceIds: state => state.department.selectedApplianceIds,
      _selectedStaticTypeIds: state => state.selectedStaticTypeIds,
    }),
    textNew () {
      return getText(this.events.length, "нова", "нові", "нових")
    },
    textEvents() {
      return getText(this.events.length, "подія", "події", "подій")
    },
    hasEvents() {
      return !_.isNull(this.events) && this.events.length > 0
    },
    isDownloading() {
      return _.isNull(this.events) && getEventsByFilters.active.value
    }
  },

  methods: {
    ...mapActions(["setPoints", "setInfo", "setTailsPoints"]),
    ...mapActions({
      _selectApplianceId: "department/selectApplianceId",
      _setAppliancesPoints: "department/setAppliancesPoints",
      _setCenteredApplianceId: "department/setCenteredApplianceId",
      _addVisibleApplianceIds: "department/addVisibleApplianceIds",
    }),

    drawCreatedEvents (events) {
      const vm = this;

      CREATED_EVENTS_POINTS.forEach(p => p.remove());
      CREATED_EVENTS_POINTS = [];

      events?.forEach(event => {
        const icon = L.divIcon({
          className: "sos-marker",
          html: `<span title="Перейти до активації та оформлення події"><i class="fas fa-map-marker-alt"></i></span>`,
          iconSize: [20, 20],
        });

        const marker = L.marker([event.coordinate.latitude, event.coordinate.longitude], { 
          icon,
          zIndexOffset: 10000,
        });

        marker.on("click", () => {
          const { href } = vm.$router.resolve({ name: "activate-event", params: { id: event.id } });
          window.open(href);
          vm.turnOffAlarm();
        });

        marker.addTo(this.map);
        CREATED_EVENTS_POINTS.push(marker);
      });
    },

    async fetchAndDisplayEventsOnMap(checkNew = false){
      const result = await getEventsByFilters.run({status: "created", limit: 1000});
      const events = result.data;
      this.drawCreatedEvents(events);
      if (checkNew) {
        const dif = _.differenceWith(events, this.events, _.isEqual);
        if (dif.length && this.alarmOn) this.playSound();
      }
      this.events = events;
    },

    async addAppliancesGroups ({ applianceGroupActive, applianceGroupPassive } = {}) {
      APPLIANCE_GROUPS_STORE_OLD = [...APPLIANCE_GROUPS_STORE_NEW];
      APPLIANCE_GROUPS_STORE_NEW = [];

      if (applianceGroupPassive) {
        applianceGroupPassive.addTo(this.map);
        APPLIANCE_GROUPS_STORE_NEW.push(applianceGroupPassive);
      }

      if (applianceGroupActive) {
        applianceGroupActive.addTo(this.map);
        APPLIANCE_GROUPS_STORE_NEW.push(applianceGroupActive);
      }
    },

    removeAppliancesGroups (deleteNew) {
      APPLIANCE_GROUPS_STORE_OLD.forEach((lGroup, index) => {
        lGroup.remove();
        APPLIANCE_GROUPS_STORE_OLD[index] = null;
      });
      APPLIANCE_GROUPS_STORE_OLD = APPLIANCE_GROUPS_STORE_OLD.filter(g => g !== null);

      if (deleteNew) {
        APPLIANCE_GROUPS_STORE_NEW.forEach((lGroup, index) => {
          lGroup.remove();
          APPLIANCE_GROUPS_STORE_NEW[index] = null;
        });
        APPLIANCE_GROUPS_STORE_NEW = APPLIANCE_GROUPS_STORE_NEW.filter(g => g !== null);
      }
    },

    async downloadApplianceTracks () {
      const points = await pointApi.fetchTracks(this._visibleApplianceIds, this.daterange);
      await this._setAppliancesPoints(points);
    },

    updateAppliancePointsOnMap () {
      if (this._passiveAppliancesPoints.length === 0 && this._activeAppliancesPoints.length === 0) {
        this.removeAppliancesGroups(true);
        return;
      }

      const passivePoints = this._passiveAppliancesPoints.map(p => ({ ...p, selected: false }));
      const activePoints = this._activeAppliancesPoints.map(p => ({ ...p, selected: true }));

      const applianceGroupPassive = mapTracks(passivePoints, 1000 * 60 * 30, this.map, this.clickOnPolyline);
      const applianceGroupActive = mapTracks(activePoints, 1000 * 60 * 30, this.map, this.clickOnPolyline);

      this.addAppliancesGroups({ applianceGroupPassive, applianceGroupActive });
      this.removeAppliancesGroups();
    },
    /** @param polylineData {{applianceId: number}} */
    clickOnPolyline (polylineData) {
      this._selectApplianceId(polylineData.applianceId);
      this._setCenteredApplianceId(polylineData.applianceId);
    },

    async loadPoints () {
      const points = await pointApi.fetchLastPoints([], this.daterange);
      const self = this;

      POINTS_STORE.forEach(p => p.remove());
      POINTS_STORE = [];

      points.forEach(point => {
        const lPoint = PointMarker(point, async () => {
          await self._addVisibleApplianceIds([point.appliance.id]);
          self._selectApplianceId(point.appliance.id);
          self._setCenteredApplianceId(point.appliance.id);
        });
        lPoint.addTo(this.map);
        POINTS_STORE.push(lPoint);
      });
    },

    /**
     * @param lat {number}
     * @param lng {number}
     * @param zoom {null | number}
     */
    async centerMap (lat, lng, zoom = null) {
      const coordinate = L.latLng(lat, lng);
      if (zoom !== null) this.map.setView(coordinate, zoom);
      else this.map.setView(coordinate);
    },

    //     const latlng = L.latLng(hash.split(",")[0], hash.split(",")[1]);
    //     this.map.setView(latlng, hash.split(",")[2]);

    /** @param applianceId {Appliance["id"]} */
    async centerMapByAppliance (applianceId) {
      if (applianceId === -1) return;

      const point = await pointApi.fetchLastPointOfAppliance(applianceId);

      if (!point) {
        this._setCenteredApplianceId(-1);
        alert("Координат не знайдено");
        return;
      }

      this.centeredProgrammatically = true;
      this.centerMap(point.coordinates.latitude, point.coordinates.longitude);
    },

    /** @param staticTypeIds {Array<StaticType["id"]>}*/
    async drawStaticObjects (staticTypeIds) {
      const staticTypeIdsToFetch = staticTypeIds.filter(id => !STATIC_TYPES_DROWN.includes(id));
      const objects = await staticApi.fetchObjects(staticTypeIdsToFetch);

      objects.forEach(object => {
        const marker = drawStatic(this.map, object);
        STATIC_OBJECTS_DROWN.push({ typeId: object.type.id, object: marker });
      });

      STATIC_TYPES_DROWN = [...staticTypeIds];

      STATIC_OBJECTS_DROWN.forEach((drawnObject, index) => {
        if (!STATIC_TYPES_DROWN.includes(drawnObject.typeId)) {
          drawnObject.object.remove();
        }
      });
      STATIC_OBJECTS_DROWN = STATIC_OBJECTS_DROWN.filter(o => STATIC_TYPES_DROWN.includes(o.typeId));
    },

    initTilesSources () {
      this.map_types.forEach((source) => {
        if (source.val.includes("http")) {
          this.tilesLayers.push(
            L.tileLayer(`${source.val}`, { key: source.key }),
          );
        }
        else {
          this.tilesLayers.push(
            L.gridLayer.googleMutant({
              type: source.val,
              key: source.key,
            }),
          );
        }
      });
    },

    initMap () {
      this.map = L.map("mapid", {
        center: this.map_center,
        zoom: 13,
      });
      L.control
        .mouseCoordinate({ utm: true, utmref: true, nac: true, qth: true })
        .addTo(this.map);
      L.edgeScaleBar().addTo(this.map);
      this.initTilesSources();
      this.tilesLayers[this.tileSourceIndex].addTo(this.map);

      const sidebar = L.control
        .sidebar({
          autopan: false, // whether to maintain the centered map point when opening the sidebar
          closeButton: true, // whether t add a close button to the panes
          container: "sidebar", // the DOM container or #ID of a predefined sidebar container that should be used
          position: "left", // left or right
        })
        .addTo(this.map);

      sidebarPanes.forEach((pane) => {
        sidebar.addPanel(pane);
      });

      document.addEventListener("keydown", (event) => {
        if (event.ctrlKey && event.keyCode == 80) {
          event.preventDefault();
          const mapcontainer = document.getElementById("mapid");
          mapcontainer.style.height = "7in";
          mapcontainer.style.width = "11in";
          this.map.invalidateSize();
          this.printing = true;
          setTimeout(() => {
            window.print();
            document.onmousemove = normalizeMap;
          }, 500);
          return false;
        }
      });
      const normalizeMap = (event) => {
        const mapcontainer = document.getElementById("mapid");
        mapcontainer.style.height = null;
        mapcontainer.style.width = null;
        this.map.invalidateSize();
        this.printing = false;
        document.onmousemove = null;
      };

      window.addEventListener("resize", () => {
        if (this.$windowSizes().width < 800) {
          sidebarPanes.forEach((pane) => {
            if (pane.is_mobile) {
              sidebar.enablePanel(pane.id);
            }
          });
        }
        else {
          sidebarPanes.forEach((pane) => {
            if (pane.is_mobile) {
              sidebar.disablePanel(pane.id);
            }
          });
        }
      });

      const input = this.$el.querySelector("#locationSearchSide");
      const options = {
        types: [],
      };
      const autocomplete = new google.maps.places.Autocomplete(input, options);
      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        this.$store.dispatch("map_center", [lat, lng]);
      });

      L.Control.TopRightControls = L.Control.extend({
        onAdd: function (map) {
          const div = L.DomUtil.create("div");
          const innerBox = L.DomUtil.create("div");
          innerBox.id = "weather";
          div.appendChild(innerBox);
          const innerBox1 = L.DomUtil.create("div");
          innerBox1.id = "point-info";
          div.appendChild(innerBox1);
          div.addEventListener('click', (event) => {
            event.stopPropagation();
          })
          return div;
        },
      });

      L.control.TopRightControls = function (opts) {
        return new L.Control.TopRightControls(opts);
      };

      L.control.TopRightControls({ position: "topright" }).addTo(this.map);

      L.Control.TopLeftControls = L.Control.extend({
        onAdd: function (map) {
          const div = L.DomUtil.create("div");
          const innerBox = L.DomUtil.create("div");
          innerBox.id = "controls";
          div.appendChild(innerBox);
          return div;
        }
      });

      L.control.TopLeftControls = function (opts) {
        return new L.Control.TopLeftControls(opts);
      };

      L.control.TopLeftControls({ position: "topleft" }).addTo(this.map);
      // TODO refactor this, devtools cant debug this [22.11.2020]
      new Vue({ ...Weather, store }).$mount("#weather");
      new Vue({ ...Weather, store }).$mount("#weather-app");
      new Vue({ ...Control, store }).$mount("#controls");
      new Vue({ ...MapType, store }).$mount("#maptype-app");
      new Vue({ parent: this, ...DateRange, store }).$mount("#daterange-app");
      new Vue({ ...Options, store }).$mount("#options-app");
      new Vue({ ...PointInfo, store }).$mount("#point-info");
      new Vue({ parent: this, ...MRSUList, store }).$mount("#mrsus-app");
      new Vue({ ...MapSidebarSearch, store }).$mount("#search-app");
      new Vue({ ...staticLayers, store }).$mount("#static-app");
      new Vue({ parent: this, ...EventList, router }).$mount("#event-app");
      new Vue({ parent: this, ...UserSettings }).$mount("#user-settings");

      // window.addEventListener(
      //   "hashchange",
      //   () => {
      //     const hash = window.location.hash.replace("#", "");
      //     const latlng = L.latLng(hash.split(",")[0], hash.split(",")[1]);
      //     this.map.setView(latlng, hash.split(",")[2]);
      //   },
      //   false,
      // );

      if (window.location.hash.length === 0) {
        window.location.hash = [this.map_center.join(","), 13].join(",");
      }

      this.map.on("moveend", (ev) => {
        window.location.hash = [
          this.map.getCenter().lat,
          this.map.getCenter().lng,
          this.map.getZoom(),
        ].join(",");

        if (!this.centeredProgrammatically) this._setCenteredApplianceId(-1);
        else this.centeredProgrammatically = false;

        const { lat, lng } = this.map.getCenter();
        getWeatherByPoint(lat, lng).then((data) => {
          this.$store.commit("SET_WEATHER", data);
        });
      });
      const ev = new Event("hashchange");
      window.dispatchEvent(ev);

      this.map.addEventListener('click', (event) => {
        const c = 100000;
        let lat = Math.round(event.latlng.lat * c) / c;
        let lng = Math.round(event.latlng.lng * c) / c;
        this.drawPin(lat, lng);
      });
    },
    initialCenterMap () {
      const [lat, lng, zoom] = window.location.hash.replace("#", "").split(",").map(i => Number(i));
      this.centerMap(lat, lng, zoom);
    },
    alarmTurnOn () {
      this.sound = new Howl({
        src: soundSrc,
        volume: 1
      });
      this.alarmOn = true;
    },
    playSound () {
      this.sound?.stop(undefined);
      this.sound?.play(undefined, false);
    },
    turnOffAlarm () {
      this.alarmOn = false;
      this.sound?.stop(undefined);

      setTimeout(() => {
        this.alarmOn = true;
      }, 2 * 1000)
    },
    goToNewEvents () {
      const { href } = this.$router.resolve({ name: "new-events" });
      window.open(href);
      this.turnOffAlarm();
    },
    removePin () {
      this.pin?.remove();
      this.pin = null;
    },
    drawPin (lat, lng) {
      this.pin?.remove();
      const icon = L.divIcon({
        className: "click-marker",
        html: `<span><i class="fas fa-map-pin"></i></span>`,
        iconSize: [20, 20],
      });
      const marker = L.marker([lat, lng], {
        icon, zIndexOffset: 10000,
      });
      marker.addTo(this.map);
      this.pin = marker;
    }
  },
  // methods up here
  watch: {
    _visibleApplianceIds: "downloadApplianceTracks",
    _visibleAppliancesPoints: "updateAppliancePointsOnMap",
    _selectedApplianceIds: "updateAppliancePointsOnMap",
    _centeredApplianceId: "centerMapByAppliance",
    _selectedStaticTypeIds: "drawStaticObjects",
    staticObjects () {
      for (var i = 0; i < this.staticLayers.length; i++) {
        this.map.removeLayer(this.staticLayers[i]);
      }
      for (var i = 0; i < this.staticObjects.length; i++) {
        this.staticLayers.push(drawStatic(this.map, this.staticObjects[i]));
      }
    },
    lst_point_info_id () {
      if (this.lst_point_info_id) {
        const point = this.pointsLayers.find((x) => {
          return x.options.applianceId === this.lst_point_info_id;
        });
        // map_center
        this.$store.dispatch("map_center", [
          point.getLatLng().lat,
          point.getLatLng().lng,
        ]);
        point._icon.click();
        this.$store.dispatch("setLastPointInfo", undefined);
      }
    },
    hovered_altitude () {
      const hovered_altitude = this.tailsPointsLayers.find((x) => {
        return x.options.pointId === this.hovered_altitude.id;
      });
      for (let i = 0; i < this.tailsPointsLayers.length; i++) {
        this.tailsPointsLayers[i]._icon.classList.remove("hovered");
      }
      hovered_altitude._icon.classList.add("hovered");
    },
    map_type () {
      this.map.removeLayer(this.tilesLayers[this.tileSourceIndex]);
      this.tileSourceIndex = this.tilesLayers.findIndex((x) => {
        return x.options.key === this.map_type;
      });
      this.map.addLayer(this.tilesLayers[this.tileSourceIndex]);
    },
    map_center () {
      const hash = window.location.hash.replace("#", "");
      const hashArr = hash.split(",");
      hashArr[0] = this.map_center[0];
      hashArr[1] = this.map_center[1];

      window.location.hash = hashArr.join(",");
      this.centerMap(this.map_center[0], this.map_center[1]);
    },
    daterange () {
      for (let i = 0; i < this.pointsLayers.length; i++) {
        this.map.removeLayer(this.pointsLayers[i]);
        this.pointsLayers.splice(i, 1);
      }
      this.loadPoints();
      this.downloadApplianceTracks();
    },
    points_on_map () {
      const applianceIds = [];
      for (var i = 0; i < this.points_on_map.length; i++) {
        applianceIds.push(this.points_on_map[i].appliance.id);
        const PointExist = this.pointsLayers.find((x) => {
          return this.points_on_map[i].appliance.id === x.options.applianceId;
        });
        if (!PointExist) {
          const point = this.points_on_map[i];
          const markerOnClick = () => {
            this.setInfo(point);
          };
          const marker = PointMarker(point, markerOnClick);

          this.pointsLayers.push(marker.addTo(this.map));

          // let layer = drawPoint(this.map, this.points_on_map[i], "", true);
          // this.pointsLayers.push(layer);
        }
      }
      for (var i = 0; i < this.pointsLayers.length; i++) {
        if (!applianceIds.includes(this.pointsLayers[i].options.applianceId)) {
          this.map.removeLayer(this.pointsLayers[i]);
          this.pointsLayers.splice(i, 1);
        }
      }
      // console.log('this.pointsLayers', this.pointsLayers, 'points_on_map', this.points_on_map)
    },
  },
  mounted () {
    this.initMap();
    document.addEventListener('click', this.alarmTurnOn, {once: true});

    setInterval(() => {
      for (let i = 0; i < this.pointsLayers.length; i++) {
        this.map.removeLayer(this.pointsLayers[i]);
        this.pointsLayers.splice(i, 1);
      }
      // this.loadTails();
      this.updateAppliancePointsOnMap();
      this.loadPoints();
      this.fetchAndDisplayEventsOnMap(true);
    }, 30 * 1000);

    this.fetchAndDisplayEventsOnMap();
    this.initialCenterMap();

    const context = new AudioContext();
    this.alarmOn = context.state === "running";
  }
};
</script>

<style lang="scss">
@import "../styles/map";
@import "../styles/leaflet-sidebar";
</style>
