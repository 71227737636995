<template lang="pug">
  .point-info(v-if="point" @click="close")
    .ta-right
      i.far.fa-times-circle.cursor(@click="$emit('click')")

    ul
      li
        strong {{point.appliance.call_sign}}
        span {{point.appliance.type ? ` (${point.appliance.type.name})` : ""}}
      li
        strong Кількість супутників
        | -
        span {{parseInfo('S')}}
      li
        strong статус
        | -
        span {{point.state}}
      li
        strong дата кінцевої координати
        | -
        span {{pointDate}}
      li
        strong коментар
        | -
        span {{point.info || "нема даних"}}
      li
        strong тиск
        | -
        span {{parseInfo('hPa')}}
      li
        strong температура приладу
        | -
        span {{parseInfo('C')}}
      li
        strong напруга батареї
        | -
        span {{parseInfo('V')}}
      li
        strong курс
        | -
        span {{point.course || "нема даних"}}
      li
        strong пройдена відстань
        | -
        span {{parseInfo('Km')}}
      li
        strong швидкість руху
        | -
        span {{point.speed? `${point.speed} км/год` : "нема даних"}}
      li
        strong висота над рівнем моря
        | -
        span {{point.altitude? `${point.altitude}  м.` : "нема даних"}}
</template>

<script>
import { mapActions, mapState } from "vuex";
import now from "dayjs";

import { fetchLastPointOfAppliance } from "../../api/point";

export default {
  data: () => ({
    /** @type {null | Point} */
    point: null,
  }),
  computed: {
    ...mapState({
      _centeredApplianceId: state => state.department.centeredApplianceId,
    }),
    pointDate () {
      if (!this.point || this.point.time === null) return "";

      const date = now(this.point.time);
      return date.format("DD.MM.YYYY HH:mm:ss");
    },
  },
  methods: {
    ...mapActions({
      _setCenteredApplianceId: "department/setCenteredApplianceId",
    }),
    parseInfo (key) {
      let val = "Відсутні дані";

      if (this.point === null) return val;

      const props = this.point.info.split(" ");
      for (let i = 0; i < props.length; i++) {
        if (props[i].includes(key)) {
          val = props[i];
        }
      }

      if (key === "S") val = parseFloat(val.replace("S", "")).toString();

      return val;
    },

    close () {
      this.point = null;
    },

    async setPoint () {
      if (this._centeredApplianceId === -1) this.close();
      else this.point = await fetchLastPointOfAppliance(this._centeredApplianceId);
    },
  },
  watch: {
    _centeredApplianceId: "setPoint",
  },
};
</script>

<style lang="css" scoped>
li > span {
  margin-left: 5px;
}

li > strong {
  margin-right: 5px;
}

.cursor {
  cursor: pointer;
}

.ta-right {
  float: right;
  padding: 5px;
  margin: -5px;
  cursor: pointer;
}
</style>
