import L from "leaflet";
// import DATA from "./tracks.json";

const groupByKey = (data, keyName) => {
  return data.reduce((accumulator, current) => {
    const key = current[keyName];

    if (accumulator[key]) {
      accumulator[key].push(current);
    }
    else {
      accumulator[key] = [current];
    }
    return accumulator;
  }, {});
};

/**
 * @param  {} arr
 * @param  {} rule boolean function checks if in current stack
 *
 */
const splitArrBy = (arr, rule = () => false) => {
  const chanks = [];
  let stepChank = [];

  arr.forEach((element) => {
    if (stepChank.length) {
      if (rule(stepChank, element)) {
        stepChank.push(element);
      }
      else {
        chanks.push(stepChank);
        stepChank = [element];
      }
    }
    else {
      stepChank.push(element);
    }
  });

  if (stepChank.length) {
    chanks.push(stepChank);
  }

  return chanks;
};

const getLeafletPolylineData = (data, timeInterval) => {
  // sort by time
  const sortedByTime = JSON.parse(JSON.stringify(data))
    .map((item) => {
      item.time = new Date(item.time);
      return item;
    })
    .sort((a, b) => {
      return a.time - b.time;
    });

  const sortedByTimeWihtStatus = sortedByTime
    .map((item) => {
    // ADD field: $status
      item.$status = item.state.includes("OK") ? "OK" : "SOS";

      // ADD field: $applianceId
      item.$applianceId = item.appliance.id;
      return item;
    });

  const gropedByAppliance = groupByKey(sortedByTimeWihtStatus, "$applianceId");

  const tracksByPeriod = [];
  const timeRule = (stepChank, element) => {
    return (
      Math.abs(stepChank[stepChank.length - 1].time - element.time) <
      timeInterval
    );
  };

  const trackByStatus = [];
  const statusRule = (stepChank, element) => {
    return stepChank[stepChank.length - 1].$status === element.$status;
  };

  for (const key in gropedByAppliance) {
    const element = gropedByAppliance[key];
    tracksByPeriod.push(...splitArrBy(element, timeRule));
  }

  tracksByPeriod.forEach((element) => {
    trackByStatus.push(...splitArrBy(element, statusRule));
  });

  let polylines = trackByStatus.map(track => {
    const color = track[0].$status === "OK" ? "green" : "red";
    const callsign = track[0].appliance ? track[0].appliance.call_sign : `appliance #${track[0].appliance.id}`;
    // DEBUG: show detailed tooltip
    // const tooltip = `${callsign}[${track.length}]: ${track[0].time.toLocaleTimeString()} - ${track[track.length - 1].time.toLocaleTimeString()}`;
    const tooltip = callsign;

    const points = track.map(item => ({
      id: item.id,
      applianceId: item.appliance.id,
      point: [item.coordinates.latitude, item.coordinates.longitude],
    }));

    return {
      selected: track[0].selected,
      status: track[0].$status,
      color,
      tooltip,
      applianceId: track[0].appliance.id,
      lastPointTime: track[track.length - 1].time,
      points,
    };
  });

  polylines = addStartPointToPolylineWithDifferentStatus(polylines, sortedByTime, timeInterval);

  return polylines
    .filter(ps => ps.points.length > 1)
    .map(polylineData => {
      polylineData.points = polylineData.points.map(p => p.point);
      return polylineData;
    });
};

function addStartPointToPolylineWithDifferentStatus (polylines, sortedPoints, timeInterval) {
  return polylines.map(polyline => {
    const firstPointId = polyline.points[0].id;
    const sortedPointsOfAppliance = sortedPoints.filter(p => p.appliance.id === polyline.applianceId);

    const firstPointIndex = sortedPointsOfAppliance.findIndex(p => p.id === firstPointId);
    const firstAnotherStatusPoint = sortedPointsOfAppliance[firstPointIndex - 1];

    if (
      firstAnotherStatusPoint &&
      firstAnotherStatusPoint.$status !== polyline.status &&
      Math.abs(firstAnotherStatusPoint.time - polyline.lastPointTime) < timeInterval
    ) {
      polyline.points.unshift({
        id: firstAnotherStatusPoint.id,
        point: [firstAnotherStatusPoint.coordinates.latitude, firstAnotherStatusPoint.coordinates.longitude],
      });
    }

    return polyline;
  });
}

/**
 * @param  {} data
 * @param  {} timeInterval timestamp
 * @param  {} map // ONLY FOR DEBUG
 * @returns Group of layers, ease to remove type of [layerGroup]
 */
export const mapTracks = (data, timeInterval, map, onClick) => {
  // DEBUG: with points
  // data.forEach(point => {
  //   const marker = L.circleMarker(point, {
  //     color: point.state === "OK" ? "green" : "red",
  //     className: "rescuer is-normal",
  //     radius: 15,
  //   });
  //   marker
  //     .bindTooltip(`[${point.id}] ${
  //       point.lat
  //     }-${
  //       point.lng
  //     }: ${
  //       new Date(point.time).toLocaleDateString()
  //     } ${
  //       new Date(point.time).toLocaleTimeString()
  //     }`)
  //     .openTooltip();
  //   marker.addTo(map);
  // });

  const polylines = getLeafletPolylineData(data, timeInterval);
  polylines.sort((d1, d2) => d1.selected ? 1 : d2.selected ? 1 : 0);

  const pLineGroup = L.layerGroup();

  polylines.forEach(polylineData => {
    const polylineOptions = {
      color: polylineData.selected ? polylineData.color : "grey",
      weight: 6,
      bubblingMouseEvents: false,
    };

    const polyline = L.polyline(polylineData.points, polylineOptions);
    polyline.bindTooltip(polylineData.tooltip).openTooltip();

    if (polylineData.selected) {
      const polylineWrapper = L.polyline(polylineData.points, { color: "#0000ff", weight: 9 });
      pLineGroup.addLayer(polylineWrapper);
    }

    polyline.on("click", () => onClick(polylineData));

    pLineGroup.addLayer(polyline);
  });

  return pLineGroup;
};
