<template lang="pug">
.maptype
  div.v-step(v-for="type in map_types")
    label.label.radio-label
      input(type="radio" name="maptype" :value="type.key" :selected="map_type == type.key" v-model="source" @change="setMapType" )
      span
      span {{type.name}}
</template>

<script>
import { mapState } from "vuex";
export default {
  data: function () {
    return {
      source: "osm",
    };
  },
  methods: {
    setMapType () {
      this.$store.dispatch("setMapType", this.source);
    },
  },
  computed: {
    ...mapState([
      "map_types",
      "map_type",
    ]),
  },
};
</script>

<style lang="sass" scoped>
</style>
