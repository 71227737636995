import { request } from "./request";
import generateParams from "../utils/generate-params";
export function fetchTypes() {
    return request.get("/static_objects_types").then(res => res.results);
}
export function fetchObjects(staticTypes) {
    if (staticTypes.length === 0)
        return Promise.resolve([]);
    const params = { limit: 1000 };
    Object.assign(params, generateParams("type", "in", staticTypes, ","));
    return request.get("/static_objects", { params }).then(res => res.results);
}
