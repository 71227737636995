<template>
  <div class="weather">
    <template v-if="wether_info.data">
      <div>
        <div class="wind">
          <div v-if="wether_info.data.wind" class="wind--pover">
            <strong>швидкість вітру</strong>
            {{ wether_info.data.wind.speed }} м/с.
            {{ wind_translate(direction(wether_info.data.wind.deg)) }}
            {{ wether_info.data.wind.deg }}°
          </div>
          <div
            v-if="wether_info.data.wind"
            class="wind--direction"
            :class="direction(wether_info.data.wind.deg)"
          >
            <i class="fas fa-location-arrow" />
          </div>
        </div>
        <div class="temperature">
          <strong>температура повітр</strong>
          {{ (wether_info.data.main.temp - 273.15) | toFixed2 }} °C
        </div>
        <div class="visibility">
          <strong>видимість</strong> {{ wether_info.data.visibility }} м.
        </div>
        <div class="presure">
          <strong>тиск</strong>
          {{ (wether_info.data.main.pressure * 0.750061683) | toFixed2 }} мм. р.
          с.
        </div>
        <div class="humidity">
          <strong>вологість</strong> {{ wether_info.data.main.humidity }} %
        </div>
      </div>
    </template>
    <template v-else>
      <div>Дані недоступні</div>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { direction } from "../../collections";
export default {
  computed: {
    ...mapState(["wether_info"]),
  },
  methods: {
    wind_translate (wind) {
      const en = ["n", "e", "s", "w"];
      const uk = ["Пн", "С", "Пд", "З"];
      const output = [];
      var i = wind.length;
      for (var i = 0; i < wind.length; i++) {
        const index = en.indexOf(wind[i]);
        output.push(uk[index]);
      }
      return output.join(",");
    },
    toggle_widget () {
      this.opened = !this.opened;
    },
    direction (deg) {
      return direction(deg);
    },
  },
};
</script>

<style lang="css" scoped>
</style>
